import React from "react";
import useFilterUrl from "./useFilterUrl";

function convertOperator(operator) {
  switch (operator) {
    case "contains":
      return "like";
    case "not contains":
      return "notLike";
    case "equals":
      return "eq";
    case "startsWith":
      return "like";
    case "endsWith":
      return "like";
    case "=":
      return "eq";
    case "not":
    case "!=":
      return "neq";
    case "after":
    case ">":
      return "gt";
    case "onOrAfter":
    case ">=":
      return "gte";
    case "before":
    case "<":
      return "lt";
    case "onOrBefore":
    case "<=":
      return "lte";
    case "is":
      return "is";
    case "isEmpty":
      return "is";
    case "isNotEmpty":
      return "isNot";
    case "isAnyOf":
    case "in":
      return "in"
    default:
      return "eq";
  }
}

function convertValue(operator, value, columnField, columns) {
  if (operator === "isEmpty" || operator === "isNotEmpty") {
    return null;
  }
  if (operator === "isAnyOf") { // convert string to array ie. sh_Ibisnew_h1428,sh_Ibisnew_h1404
    if (Array.isArray(value)) {
      const result = [];
      for (const v of value) {
        result.push(...v.split(','))
      }
      return result;
    }
    return value;
  }
  if (operator === "in") { // convert string to array ie. sh_Ibisnew_h1428,sh_Ibisnew_h1404
    if (value) {
      return value.split(",").map((v) => v.trim());
    } else {
      return value;
    }
  }
  const column = columns.find((col) => col.field === columnField);

  let prefix, suffix;
  if (
    operator === "contains" ||
    operator === "endsWith" ||
    operator === "not contains"
  ) {
    prefix = "%";
  }
  if (column?.type === "number") {
    value = parseInt(value);
  }
  if (column?.type === "boolean") {
    value = value === "true";
  }
  if (
    operator === "contains" ||
    operator === "startsWith" ||
    operator === "not contains"
  ) {
    suffix = "%";
  }
  if (prefix) {
    value = prefix + value;
  }
  if (suffix) {
    value = value + suffix;
  }
  return value;
}

const generateFilter = (filterValue, columns) => {
  const filter =
    filterValue?.items &&
      filterValue?.items.length > 0 &&
      (filterValue?.items[0].operator === "isEmpty" ||
        filterValue?.items[0].operator === "isNotEmpty" ||
        typeof filterValue?.items[0].value !== 'undefined')
      ? {
        [filterValue.logicOperator]: filterValue.items.map((item) => {
          if (item.field.includes(".")) {
            const fields = item.field.split(".");
            return {
              [fields[0]]: {
                [fields[1]]: {
                  [convertOperator(item.operator)]: convertValue(
                    item.operator,
                    item.value,
                    item.field,
                    columns
                  ),
                },
              },
            };
          } else {
            const filter = {
              [item.field]: {
                [convertOperator(item.operator)]: convertValue(
                  item.operator,
                  item.value,
                  item.field,
                  columns
                ),
              },
            };
            return filter;
          }
        }),
      }
      : {};
  return filter;
};

export default function useDatagrid({
  id,
  columns,
  defaultColumnVisibilityModel,
  defaultFilter,
  defaultSort,
}) {
  const { compress } = useFilterUrl();

  const [selectionModel, setSelectionModel] = React.useState(
    JSON.parse(window.localStorage.getItem(`selectionModel${id}`)) || []
  );

  const setSelectionModelStored = (value) => {
    setSelectionModel(value);
    window.localStorage.setItem(`selectionModel${id}`, JSON.stringify(value));
  };

  const rowsPerPageOptions = [20, 50, 100, 1000, 5000];

  const [pageSize, setPageSize] = React.useState(
    JSON.parse(window.localStorage.getItem(`pageSize${id}`)) || 20
  );

  /*
  const setPageSizeStored = (value) => {
    setPageSize(value);
    window.localStorage.setItem(`pageSize${id}`, JSON.stringify(value));
  };
  */

  const [page, setPage] = React.useState(
    JSON.parse(window.localStorage.getItem(`page${id}`)) || 0
  );

  /*
  const setPageStored = (value) => {
    setPage(value);
    window.localStorage.setItem(`page${id}`, JSON.stringify(value));
  };
  */

  const handlePaginationModelChange = ({ page, pageSize }) => {
    setPageSize(pageSize);
    window.localStorage.setItem(`pageSize${id}`, JSON.stringify(pageSize));

    setPage(page);
    window.localStorage.setItem(`page${id}`, JSON.stringify(page));
  }

  const paging = { limit: pageSize, offset: page * pageSize };

  const [sortModel, setSortModel] = React.useState(
    JSON.parse(window.localStorage.getItem(`sort${id}`)) || defaultSort || []
  );
  const setSortModelStored = (value) => {
    setSortModel(value);
    window.localStorage.setItem(`sort${id}`, JSON.stringify(value));
  };

  const sorting = sortModel.map((sort) => {
    return { direction: sort.sort.toUpperCase(), field: sort.field };
  });

  const [filterValue, setFilterValue] = React.useState(
    defaultFilter ||
    JSON.parse(window.localStorage.getItem(`filter${id}`)) ||
    undefined
  );

  const onFilterChange = React.useCallback(
    (filterModel) => {
      setFilterValue(filterModel);
      window.localStorage.setItem(`filter${id}`, JSON.stringify(filterModel));
    },
    [id]
  );

  const shareFilter = (handleResult) => {
    compress(filterValue, handleResult);
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    JSON.parse(window.localStorage.getItem(`visibilityModel${id}`)) ||
    defaultColumnVisibilityModel ||
    undefined
  );

  const onColumnVisibilityModelChange = (visibilityModel) => {
    setColumnVisibilityModel(visibilityModel);
    window.localStorage.setItem(`visibilityModel${id}`, JSON.stringify(visibilityModel));
  };

  const filter = generateFilter(filterValue, columns);

  return {
    selectionModel,
    setSelectionModel: setSelectionModelStored,
    pageSize,
    page,
    handlePaginationModelChange,
    paging,
    rowsPerPageOptions,
    sortModel,
    setSortModel: setSortModelStored,
    sorting,
    filterValue,
    onFilterChange,
    filter,
    onColumnVisibilityModelChange,
    shareFilter,
    columnVisibilityModel
  };
}

export { generateFilter };
