import React from "react";
import { loader } from "graphql.macro";
import useDatagrid from "../../hooks/useDatagrid";
import DataGrid from "../DataGrid";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Link,
  Stack,
  Tooltip,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { WS_STATUS } from "../../constants/websocket";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ListIcon from "@mui/icons-material/List";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PopoverImage from "../PopoverImage";

import DvrIcon from "@mui/icons-material/Dvr";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import useFilterUrl from "../../hooks/useFilterUrl";
import Progress from "../Progress";
import stringColumnType from "../DataGrid/stringColumnType";
import Support from "../Roles/Support";

const ID = "BoxList";

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

function WsStatusIcon({ status }) {
  if (status === WS_STATUS.CONNECTED) {
    return (
      <Tooltip title={status}>
        <CheckCircleOutlineIcon color="success" fontSize="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={status || ""}>
        <ErrorOutlineIcon color="warning" fontSize="small" />
      </Tooltip>
    );
  }
}

function Tags({ tags }) {
  const { loading, data } = useQuery(TAGS, {
    variables: {
      filter: { id: { in: tags } },
    },
  });
  if (loading) {
    return <Progress />;
  }
  return data.boxTags.nodes.map((tag) => (
    <Tooltip key={tag.id} title={tag.description}>
      <Chip
        size="small"
        variant="outlined"
        sx={{ margin: "10" }}
        label={tag.value}
        color={tag.color}
      />
    </Tooltip>
  ));
}

const isHidden = (field, defaultValue) => {
  let isHidden = defaultValue;
  const hide = window.localStorage.getItem(`column${ID}-${field}`);
  if (hide !== null) {
    isHidden = hide === "true";
  }
  return isHidden;
};

const TAGS = loader("../../graphql/tags.graphql");

const defaultColumnVisibilityModel = {
  date_created: false,
  configuration_conf: false,
  configuration_freescreenId: false,
  configuration_freescreenServer: false,
  configuration_group: false,
  configuration_isMediaServer: false,
  configuration_orientation: false,
  configuration_resobase: false,
  configuration_roomId: false,
  configuration_scenarioId: false,
  configuration_supervision: false,
  configuration_templateId: false,
  configuration_templateName: false,
  configuration_ocsUrl: false,
  configuration_gitUrl: false,
  configuration_pciNestClientVersion: false,
  configuration_vpTime: false,
  hardware_cpuModel: false,
  hardware_diskUsage: false,
  hardware_ram: false,
  hardware_ssdModel: false,
  hardware_architecture: false,
  hardware_model: false,
  hardware_vpModel: false,
  network_connexion: false,
  network_dns: false,
  network_freescreenServer: false,
  network_gateway: false,
  network_ipPublic: false,
  network_mac: false,
  network_ssid: false,
  network_macGateway: false,
  network_netmask: false,
  network_type: false,
  network_ntpServers: false,
  network_ipVp: false,
  software_chromeVersion: false,
  software_git: false,
  software_httpsAvailable: false,
  software_masterVersion: false,
  software_patchBrand: false,
  software_patchBrandMd5: false,
  software_patchContent: false,
  software_patchContentId: false,
  software_patchContentMd5: false,
  software_patchGeneric: false,
  software_patchGenericMd5: false,
  software_securePassword: false,
  software_teamviewerId: false,
  software_osVersion: false,
  software_gitPlayerVersion: false,
  software_availableCiphers: false,
  supervision_screenshotHash: false,
  custom_details: false,
};

const columns = [
  {
    field: "supervision_wsStatus",
    headerName: "wsStatus",
    width: 10,
    get hide() {
      return isHidden("supervision_wsStatus", false);
    },
    renderCell: (params) => (
      <Box px={1}>
        <WsStatusIcon status={params.value} />
      </Box>
    ),
  },
  {
    field: "tagIds",
    headerName: "tags",
    width: 150,
    filterable: false,
    get hide() {
      return isHidden("tagIds", false);
    },
    renderCell: (params) => {
      return (
        <Box px={1}>
          {params.value.length > 0 && (
            <Stack direction="row" spacing={1}>
              <Tags tags={params.value} />
            </Stack>
          )}
        </Box>
      );
    },
  },
  {
    field: "mac",
    width: 150,
    get hide() {
      return isHidden("mac", false);
    },
  },
  {
    field: "date_created",
    width: 200,
    get hide() {
      return isHidden("date_created", true);
    },
    type: "dateTime",
    valueFormatter: (params) =>
      new Date(params.value).toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      }),
  },
  {
    field: "date_updated",
    width: 200,
    get hide() {
      return isHidden("date_updated", false);
    },
    type: "dateTime",
    valueFormatter: (params) =>
      new Date(params.value).toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      }),
  },
  {
    field: "configuration_conf",
    headerName: "conf",
    width: 200,
    get hide() {
      return isHidden("configuration_conf", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_freescreenId",
    headerName: "freescreenId",
    width: 150,
    get hide() {
      return isHidden("configuration_freescreenId", true);
    },
    type: "number",
  },
  {
    field: "configuration_freescreenServer",
    headerName: "freescreenServer",
    width: 200,
    get hide() {
      return isHidden("configuration_freescreenServer", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_group",
    headerName: "group",
    width: 100,
    get hide() {
      return isHidden("configuration_group", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_hotel",
    headerName: "hotel",
    width: 200,
    get hide() {
      return isHidden("configuration_hotel", false);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_isMediaServer",
    headerName: "isMediaServer",
    width: 150,
    get hide() {
      return isHidden("configuration_isMediaServer", true);
    },
    type: "boolean",
  },
  {
    field: "configuration_orientation",
    headerName: "orientation",
    width: 150,
    get hide() {
      return isHidden("configuration_orientation", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_resobase",
    headerName: "resobase",
    width: 150,
    get hide() {
      return isHidden("configuration_resobase", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_roomId",
    headerName: "roomId",
    width: 100,
    get hide() {
      return isHidden("configuration_roomId", true);
    },
    type: "number",
  },
  {
    field: "configuration_scenarioId",
    headerName: "scenarioId",
    width: 150,
    get hide() {
      return isHidden("configuration_scenarioId", true);
    },
    type: "number",
  },
  {
    field: "configuration_scenarioName",
    headerName: "scenarioName",
    width: 200,
    get hide() {
      return isHidden("configuration_scenarioName", false);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_supervision",
    headerName: "supervision",
    width: 200,
    get hide() {
      return isHidden("configuration_supervision", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_templateId",
    headerName: "templateId",
    width: 150,
    get hide() {
      return isHidden("configuration_templateId", true);
    },
    type: "number",
  },
  {
    field: "configuration_templateName",
    headerName: "templateName",
    width: 200,
    get hide() {
      return isHidden("configuration_templateName", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_ocsUrl",
    headerName: "OCS url",
    width: 200,
    get hide() {
      return isHidden("configuration_ocsUrl", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_gitUrl",
    headerName: "GIT url",
    width: 200,
    get hide() {
      return isHidden("configuration_gitUrl", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_pciNestClientVersion",
    headerName: "PCI NEST Client Version",
    width: 200,
    get hide() {
      return isHidden("configuration_pciNestClientVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_vpTime",
    headerName: "VP switch-off time",
    width: 200,
    get hide() {
      return isHidden("configuration_vpTime", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_cpuModel",
    headerName: "cpuModel",
    width: 200,
    get hide() {
      return isHidden("hardware_cpuModel", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_diskUsage",
    headerName: "diskUsage",
    width: 150,
    get hide() {
      return isHidden("hardware_diskUsage", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_ram",
    headerName: "ram",
    width: 150,
    get hide() {
      return isHidden("hardware_ram", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_ssdModel",
    headerName: "ssdModel",
    width: 200,
    get hide() {
      return isHidden("hardware_ssdModel", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_architecture",
    headerName: "architecture",
    width: 100,
    get hide() {
      return isHidden("hardware_architecture", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_model",
    headerName: "model",
    width: 200,
    get hide() {
      return isHidden("hardware_model", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_vpModel",
    headerName: "VP model",
    width: 200,
    get hide() {
      return isHidden("hardware_vpModel", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_connexion",
    headerName: "connexion",
    width: 200,
    get hide() {
      return isHidden("network_connexion", true);
    },
    type: "boolean",
  },
  {
    field: "network_dns",
    headerName: "dns",
    width: 150,
    get hide() {
      return isHidden("network_dns", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_freescreenServer",
    headerName: "freescreenServer",
    width: 150,
    get hide() {
      return isHidden("network_freescreenServer", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_gateway",
    headerName: "gateway",
    width: 150,
    get hide() {
      return isHidden("network_gateway", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_ip",
    headerName: "ip",
    width: 150,
    get hide() {
      return isHidden("network_ip", false);
    },
    ...stringColumnType,
  },
  {
    field: "network_ipPublic",
    headerName: "ipPublic",
    width: 150,
    get hide() {
      return isHidden("network_ipPublic", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_mac",
    headerName: "mac",
    width: 150,
    get hide() {
      return isHidden("network_mac", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_ssid",
    headerName: "ssid",
    width: 150,
    get hide() {
      return isHidden("network_ssid", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_macGateway",
    headerName: "macGateway",
    width: 150,
    get hide() {
      return isHidden("network_macGateway", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_netmask",
    headerName: "netmask",
    width: 150,
    get hide() {
      return isHidden("network_netmask", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_type",
    headerName: "network_type",
    width: 150,
    get hide() {
      return isHidden("network_type", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_ntpServers",
    headerName: "ntpServers",
    width: 150,
    ...stringColumnType,
  },
  {
    field: "network_ipVp",
    headerName: "IP VP",
    width: 150,
    ...stringColumnType,
  },
  {
    field: "software_chromeVersion",
    headerName: "chromeVersion",
    width: 150,
    get hide() {
      return isHidden("software_chromeVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_git",
    headerName: "git",
    width: 150,
    get hide() {
      return isHidden("software_git", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_httpsAvailable",
    headerName: "httpsAvailable",
    width: 150,
    get hide() {
      return isHidden("software_httpsAvailable", true);
    },
    type: "boolean",
  },
  {
    field: "software_masterVersion",
    headerName: "masterVersion",
    width: 150,
    get hide() {
      return isHidden("software_masterVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchBrand",
    headerName: "patchBrand",
    width: 200,
    get hide() {
      return isHidden("software_patchBrand", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchBrandMd5",
    headerName: "patchBrandMd5",
    width: 200,
    get hide() {
      return isHidden("software_patchBrandMd5", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchContent",
    headerName: "patchContent",
    width: 200,
    get hide() {
      return isHidden("software_patchContent", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchContentId",
    headerName: "patchContentId",
    width: 150,
    get hide() {
      return isHidden("software_patchContentId", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchContentMd5",
    headerName: "patchContentMd5",
    width: 200,
    get hide() {
      return isHidden("software_patchContentMd5", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchGeneric",
    headerName: "patchGeneric",
    width: 200,
    get hide() {
      return isHidden("software_patchGeneric", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchGenericMd5",
    headerName: "patchGenericMd5",
    width: 200,
    get hide() {
      return isHidden("software_patchGenericMd5", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_securePassword",
    headerName: "securePassword",
    width: 150,
    get hide() {
      return isHidden("software_securePassword", true);
    },
    type: "boolean",
  },
  {
    field: "software_teamviewerId",
    headerName: "teamviewerId",
    width: 150,
    get hide() {
      return isHidden("software_teamviewerId", true);
    },
    renderCell: (params) => (
      <Link
        target="_blank"
        href={`https://start.teamviewer.com/${params.value}`}
      >
        {params.value}
      </Link>
    ),
    ...stringColumnType,
  },
  {
    field: "software_osVersion",
    headerName: "osVersion",
    width: 200,
    get hide() {
      return isHidden("software_osVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_gitPlayerVersion",
    headerName: "gitPlayerVersion",
    width: 80,
    get hide() {
      return isHidden("software_gitPlayerVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_availableCiphers",
    headerName: "availableCiphers",
    width: 80,
    get hide() {
      return isHidden("software_availableCiphers", true);
    },
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotHash",
    headerName: "screenshotHash",
    width: 120,
    get hide() {
      return isHidden("supervision_screenshotHash", true);
    },
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotDate",
    headerName: "screenshot",
    width: 150,
    renderCell: (params) => (
      <PopoverImage
        src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_SERVER}/screenshotDds/${params.row["mac"]}?${params.value}`}
        width="150"
        height="150"
        popWidth="1366"
        popHeight="768"
      ></PopoverImage>
    ),
  },
  {
    field: "custom_details",
    headerName: "customDetails",
    width: 300,
    get hide() {
      return isHidden("custom_details", true);
    },
  },
];

const QUERY = loader("../../graphql/boxes.graphql");
const DELETE = loader("../../graphql/deleteOneBox.graphql");

export default function BoxList({ consoleHook, user }) {
  const query = useUrlQuery();
  const { decompress, filterDecompressed } = useFilterUrl();

  const context = useDatagrid({
    id: ID,
    columns,
    defaultColumnVisibilityModel,
  });

  React.useEffect(() => {
    const filter = query.get("filter");
    if (filter && !filterDecompressed) {
      decompress(filter, (result) => {
        context.onFilterChange(result);
      });
    }
  });

  const selectionModel = context.selectionModel;

  let navigate = useNavigate();
  const [deleteOneBox] = useMutation(DELETE);

  function handleOpenConsole() {
    context.selectionModel.forEach((mac) => {
      consoleHook.addConsole(mac);
    });
    navigate(`/consoles/dds`);
  }

  function handleScreenshot(doRefresh) {
    consoleHook.screenshot(selectionModel, doRefresh);
  }

  function handleDelete() {
    context.selectionModel.forEach((id) => {
      deleteOneBox({
        variables: {
          input: {
            id,
          },
        },
      });
    });
    context.setSelectionModel([]);
  }

  return (
    <Box display="flex" height="85vh">
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          columns={columns}
          context={context}
          query={QUERY}
          checkboxSelection
          quickSearchColumn="configuration_hotel"
          quickSearchColumnLabel="hotel"
        />
        <ButtonGroup color="primary">
          <Tooltip title="Box Details">
            <span>
              <Button
                onClick={() =>
                  navigate("/boxes/details", {
                    state: { boxIds: selectionModel },
                  })
                }
                disabled={selectionModel.length === 0}
              >
                <ListIcon />
              </Button>
            </span>
          </Tooltip>
          <Support user={user}>
            <Tooltip title="Open console">
              <span>
                <Button
                  onClick={() => handleOpenConsole()}
                  disabled={selectionModel.length === 0}
                >
                  <DvrIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          <Tooltip title="Take screenshot">
            <span>
              <Button
                onClick={() => handleScreenshot(false)}
                disabled={selectionModel.length === 0}
              >
                <WallpaperIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Refresh + screenshot">
            <span>
              <Button
                onClick={() => handleScreenshot(true)}
                disabled={selectionModel.length === 0}
              >
                <RefreshIcon />
                <WallpaperIcon />
              </Button>
            </span>
          </Tooltip>
          <Support user={user}>
            <Tooltip title="Delete">
              <span>
                <Button
                  onClick={() => handleDelete()}
                  disabled={selectionModel.length === 0}
                >
                  <DeleteIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          <Tooltip title="Screenshot view">
            <span>
              <Button
                onClick={() =>
                  navigate(`/screenshots/boxes`, {
                    state: {
                      filter:
                        selectionModel.length === 0
                          ? context.filter
                          : {
                              mac: {
                                in: selectionModel,
                              },
                            },
                      paging: context.paging,
                      sorting: context.sorting,
                    },
                  })
                }
              >
                <InsertPhotoIcon />
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
      </div>
    </Box>
  );
}

export { columns };
