import React from "react";
import { loader } from "graphql.macro";
import useDatagrid from "../../hooks/useDatagrid";
import DataGrid from "../DataGrid";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  /*Chip,*/ Link,
  Tooltip,
} from "@mui/material";
import { useMutation /*useQuery*/ } from "@apollo/client";
import { WS_STATUS } from "../../constants/websocket";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ListIcon from "@mui/icons-material/List";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import DvrIcon from "@mui/icons-material/Dvr";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
//import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
//import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import useFilterUrl from "../../hooks/useFilterUrl";
//import Progress from "../Progress";
import PopoverImage from "../PopoverImage";
import HearingIcon from "@mui/icons-material/Hearing";
import HearingDisabledIcon from "@mui/icons-material/HearingDisabled";
import NoiseAwareIcon from "@mui/icons-material/NoiseAware";
import stringColumnType from "../DataGrid/stringColumnType";
import Support from "../Roles/Support";

const ID = "PlayerList";

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

function WsStatusIcon({ status }) {
  if (status === WS_STATUS.CONNECTED) {
    return (
      <Tooltip title={status}>
        <CheckCircleOutlineIcon color="success" fontSize="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={status || ""}>
        <ErrorOutlineIcon color="warning" fontSize="small" />
      </Tooltip>
    );
  }
}

function SoundStatusIcon({ status }) {
  if (status === true) {
    return (
      <Tooltip title="Playing">
        <HearingIcon color="success" fontSize="small" />
      </Tooltip>
    );
  } else if (status === false) {
    return (
      <Tooltip title="Not playing">
        <HearingDisabledIcon color="error" fontSize="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Unknown">
        <NoiseAwareIcon color="warning" fontSize="small" />
      </Tooltip>
    );
  }
}

/*
function Tags({ tags }) {
  const { loading, data } = useQuery(TAGS, {
    variables: {
      filter: { id: { in: tags } },
    },
  });
  if (loading) {
    return <Progress />;
  }
  return data.boxTags.nodes.map((tag) => (
    <Tooltip title={tag.description}>
      <Chip
        key={tag.id}
        size="small"
        variant="outlined"
        sx={{ margin: "10" }}
        label={tag.value}
        color={tag.color}
      />
    </Tooltip>
  ));
}
*/

const isHidden = (field, defaultValue) => {
  let isHidden = defaultValue;
  const hide = window.localStorage.getItem(`column${ID}-${field}`);
  if (hide !== null) {
    isHidden = hide === "true";
  }
  return isHidden;
};

//const TAGS = loader("../../graphql/tags.graphql");

function getPlayerConfig(params, field) {
  return params.row.playerConfig ? params.row.playerConfig[field] : null;
}

const defaultColumnVisibilityModel = {
  ipPublic: true,
  date_created: false,
  temp: false,
  downloadError: false,
  gitPullError: false,
  ramTotal: false,
  "playerplayerConfig.mac": false,
  "playerConfig.mp3Folder": false,
  "playerConfig.mp3JukeBox": false,
  "playerConfig.mp3JukeBoxKeep": false,
  "playerConfig.mp3JukeBoxCustom": false,
  "playerConfig.mongoUrl": false,
  "playerConfig.rsyncUrl": false,
  "playerConfig.syncListUrl": false,
  "playerConfig.supervision": false,
  "playerConfig.planningUrl": false,
  "playerConfig.country": false,
  "playerConfig.zone": false,
  "playerConfig.mac": false,
  "playerConfig.validated": false,
  "playerConfig.tvId": true,
  "playerConfig.pathApp": false,
  "playerConfig.ddpURL": false,
  "playerConfig.ddpPort": false,
  "playerConfig.loginUrl": false,
  "playerConfig.downloadUrl": false,
  "playerConfig.volume": false,
  "playerConfig.dlMethod": false,
  "playerConfig.pciUrl": false,
  "playerConfig.database": false,
  supervision_screenshotHash: false,
  pciNestClientVersion: false,
  nodeVersion: false,
  systemVolume: false,
};

const columns = [
  {
    field: "supervision_wsStatus",
    headerName: "wsStatus",
    width: 10,
    get hide() {
      return isHidden("supervision_wsStatus", false);
    },
    renderCell: (params) => (
      <Box px={1}>
        <WsStatusIcon status={params.value} />
      </Box>
    ),
    ...stringColumnType,
  },
  {
    field: "supervision_soundRunning",
    headerName: "playing",
    type: "boolean",
    width: 10,
    get hide() {
      return isHidden("supervision_soundRunning", false);
    },
    renderCell: (params) => (
      <Box px={1}>
        <SoundStatusIcon status={params.value} />
      </Box>
    ),
    ...stringColumnType,
  },
  {
    field: "mac",
    width: 150,
    get hide() {
      return isHidden("mac", false);
    },
    ...stringColumnType,
  },
  {
    field: "date_created",
    width: 150,
    get hide() {
      return isHidden("date_created", true);
    },
    ...stringColumnType,
  },
  {
    field: "date_updated",
    width: 150,
    get hide() {
      return isHidden("date_updated", false);
    },
    ...stringColumnType,
  },
  {
    field: "planning",
    width: 150,
    ...stringColumnType,
  },
  {
    field: "playlist",
    width: 150,
    ...stringColumnType,
  },
  {
    field: "ipPublic",
    width: 150,
    ...stringColumnType,
  },
  {
    field: "usedSpaceSystem",
    width: 100,
    get hide() {
      return isHidden("usedSpaceSystem", false);
    },
    ...stringColumnType,
  },
  {
    field: "usedSpaceData",
    width: 100,
    get hide() {
      return isHidden("usedSpaceData", false);
    },
    ...stringColumnType,
  },
  {
    field: "temp",
    width: 150,
    get hide() {
      return isHidden("temp", true);
    },
    ...stringColumnType,
  },
  {
    field: "playerVersion",
    width: 100,
    ...stringColumnType,
  },
  {
    field: "downloadError",
    width: 150,
    get hide() {
      return isHidden("downloadError", true);
    },
    ...stringColumnType,
  },
  {
    field: "gitPullError",
    width: 150,
    get hide() {
      return isHidden("gitPullError", true);
    },
    ...stringColumnType,
  },
  {
    field: "ramTotal",
    width: 150,
    ...stringColumnType,
  },
  {
    field: "systemVolume",
    width: 150,
    ...stringColumnType,
  },
  {
    field: "playerplayerConfig.mac",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "mac"),
    get hide() {
      return isHidden("playerplayerConfig.mac", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.mp3Folder",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "mp3Folder"),
    get hide() {
      return isHidden("playerConfig.mp3Folder", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.mp3JukeBox",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "mp3JukeBox"),
    get hide() {
      return isHidden("playerConfig.mp3JukeBox", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.mp3JukeBoxKeep",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "mp3JukeBoxKeep"),
    get hide() {
      return isHidden("playerConfig.mp3JukeBoxKeep", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.mp3JukeBoxCustom",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "mp3JukeBoxCustom"),
    get hide() {
      return isHidden("playerConfig.mp3JukeBoxCustom", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.mongoUrl",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "mongoUrl"),
    get hide() {
      return isHidden("playerConfig.mongoUrl", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.rsyncUrl",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "rsyncUrl"),
    get hide() {
      return isHidden("playerConfig.rsyncUrl", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.syncListUrl",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "syncListUrl"),
    get hide() {
      return isHidden("playerConfig.syncListUrl", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.supervision",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "supervision"),
    get hide() {
      return isHidden("playerConfig.supervision", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.planningUrl",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "planningUrl"),
    get hide() {
      return isHidden("playerConfig.planningUrl", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.country",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "country"),
    get hide() {
      return isHidden("playerConfig.country", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.zone",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "zone"),
    get hide() {
      return isHidden("playerConfig.zone", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.hardware",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "hardware"),
    get hide() {
      return isHidden("playerConfig.hardware", false);
    },
    sortable: false,
  },
  {
    field: "playerConfig.siteCode",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "siteCode"),
    get hide() {
      return isHidden("playerConfig.siteCode", false);
    },
    sortable: false,
  },
  {
    field: "playerConfig.ip",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "ip"),
    get hide() {
      return isHidden("playerConfig.ip", false);
    },
    sortable: false,
  },
  {
    field: "playerConfig.mac",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "mac"),
    get hide() {
      return isHidden("playerConfig.mac", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.validated",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "validated"),
    get hide() {
      return isHidden("playerConfig.validated", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.tvId",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "tvId"),
    renderCell: (params) => (
      <Link
        target="_blank"
        href={`https://start.teamviewer.com/${params.value}`}
      >
        {params.value}
      </Link>
    ),
    get hide() {
      return isHidden("playerConfig.tvId", false);
    },
    sortable: false,
  },
  {
    field: "playerConfig.pathApp",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "pathApp"),
    get hide() {
      return isHidden("playerConfig.pathApp", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.ddpURL",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "ddpURL"),
    get hide() {
      return isHidden("playerConfig.ddpURL", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.ddpPort",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "ddpPort"),
    get hide() {
      return isHidden("playerConfig.ddpPort", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.loginUrl",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "loginUrl"),
    get hide() {
      return isHidden("playerConfig.loginUrl", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.downloadUrl",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "downloadUrl"),
    get hide() {
      return isHidden("playerConfig.downloadUrl", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.offer",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "offer"),
    get hide() {
      return isHidden("playerConfig.offer", false);
    },
    sortable: false,
  },
  {
    field: "playerConfig.volume",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "volume"),
    get hide() {
      return isHidden("playerConfig.volume", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.dlMethod",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "dlMethod"),
    get hide() {
      return isHidden("playerConfig.dlMethod", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.pciUrl",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "pciUrl"),
    get hide() {
      return isHidden("playerConfig.pciUrl", true);
    },
    sortable: false,
  },
  {
    field: "playerConfig.database",
    width: 150,
    valueGetter: (params) => getPlayerConfig(params, "database"),
    sortable: false,
    ...stringColumnType,
  },
  {
    field: "pciNestClientVersion",
    headerName: "PCI client version",
    width: 100,
    ...stringColumnType,
  },
  {
    field: "nodeVersion",
    headerName: "Node version",
    width: 100,
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotHash",
    headerName: "screenshotHash",
    width: 120,
    get hide() {
      return isHidden("supervision_screenshotHash", true);
    },
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotDate",
    headerName: "screenshot",
    width: 150,
    renderCell: (params) => (
      <PopoverImage
        src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_SERVER}/screenshotMelodjy/${params.row["mac"]}?${params.value}`}
        width="150"
        height="150"
        popWidth="1366"
        popHeight="768"
      ></PopoverImage>
    ),
  },
];

const QUERY = loader("../../graphql/players.graphql");
const DELETE = loader("../../graphql/deleteOnePlayer.graphql");

export default function PlayerList({ consoleHook, user }) {
  const query = useUrlQuery();
  const { decompress, filterDecompressed } = useFilterUrl();

  const context = useDatagrid({
    id: ID,
    columns,
    defaultColumnVisibilityModel,
  });

  React.useEffect(() => {
    const filter = query.get("filter");
    if (filter && !filterDecompressed) {
      decompress(filter, (result) => {
        context.onFilterChange(result);
      });
    }
  });

  const selectionModel = context.selectionModel;

  let navigate = useNavigate();
  const [deleteOnePlayer] = useMutation(DELETE);

  function handleOpenConsole() {
    context.selectionModel.forEach((mac) => {
      consoleHook.addConsole(mac);
    });
    navigate(`/consoles/melodjy`);
  }

  function handleScreenshot(doRefresh) {
    consoleHook.screenshot(selectionModel, doRefresh);
  }

  function handleDelete() {
    context.selectionModel.forEach((id) => {
      deleteOnePlayer({
        variables: {
          input: {
            id,
          },
        },
      });
    });
    context.setSelectionModel([]);
  }

  return (
    <Box display="flex" height="85vh">
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          columns={columns}
          context={context}
          query={QUERY}
          checkboxSelection
          quickSearchColumn="playerConfig.siteCode"
          quickSearchColumnLabel="hotel"
        />
        <ButtonGroup color="primary">
          {
            <Tooltip title="Player Details">
              <Button
                onClick={() =>
                  navigate("/players/details", {
                    state: { playerIds: selectionModel },
                  })
                }
                disabled={selectionModel.length === 0}
              >
                <ListIcon />
              </Button>
            </Tooltip>
          }
          <Support user={user}>
            <Tooltip title="Open console">
              <span>
                <Button
                  onClick={() => handleOpenConsole()}
                  disabled={selectionModel.length === 0}
                >
                  <DvrIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          <Tooltip title="Take screenshot">
            <span>
              <Button
                onClick={() => handleScreenshot(false)}
                disabled={selectionModel.length === 0}
              >
                <WallpaperIcon />
              </Button>
            </span>
          </Tooltip>
          {/* <Tooltip title="Refresh + screenshot">
            <Button
              onClick={() => handleScreenshot(true)}
              disabled={selectionModel.length === 0}
            >
              <RefreshIcon />
              <WallpaperIcon />
            </Button>
          </Tooltip> */}
          <Support user={user}>
            <Tooltip title="Delete">
              <span>
                <Button
                  onClick={() => handleDelete()}
                  disabled={selectionModel.length === 0}
                >
                  <DeleteIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          {/* <Tooltip title="Screenshot view">
            <Button
              onClick={() =>
                navigate(`/screenshots/boxes`, { state : {
                  filter:
                    selectionModel.length === 0
                      ? context.filter
                      : {
                        mac: {
                          in: selectionModel,
                        },
                      },
                  paging: context.paging,
                  sorting: context.sorting,
                }})
              }
            >
              <InsertPhotoIcon />
            </Button>
          </Tooltip> */}
        </ButtonGroup>
      </div>
    </Box>
  );
}

export { columns };
